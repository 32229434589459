export const energyData = [
    {
        id: 1,
        group: "Население и приравненные к ним, за исключением населения и потребителей, указанных в пунктах 2 и 3",
        measure: "руб./кВт.ч",
        price1: "6,17",
        price2: "6,73"
    },
    
    {
        id: 1.2,
        group: "Население и приравненные к ним. за исключением населения и потребителей, указанных в п.2 и 3 по зонам суток(дневная/ночная).",
        measure: "руб./кВт.ч",
        price1: "7,1/2,82",
        price2: "8,21/3,21"
    },

    {
        id: 2.1,
        group: "Население, проживающее в городских населенных пунктах в домах, оборудованных электрическими плитами",
        measure: "руб./кВт.ч",
        price1: "4,6",
        price2: "5,05"
    },
    
     {
        id: 2.2,
        group: "Население, проживающее в городских населенных пунктах в домах, оборудованных электрическими плитами по зонам суток.(дневная/ночная).",
        measure: "руб./кВт.ч",
        price1: "5,29/2,11",
        price2: "6,16/2,43"
    },

    {
        id: 3.1,
        group: "Население, проживающее в сельских населенных пунктах",
        measure: "руб./кВт.ч",
        price1: "4,32",
        price2: "4,71"
    },

    {
        id: 3.2,
        group: "Население, проживающее в сельских населенных пунктах по зонам суток(дневная/ночная).",
        measure: "руб./кВт.ч",
        price1: "4,97/2,00",
        price2: "5,75/2,27"
    }
    
    ]